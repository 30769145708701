<template>
	<h2 style="margin: 0 auto; text-align: center;margin-top: 4%;color:#409EFF ;" >时间戳在线转换</h2>
		<div style="margin-top: 4%;">
		<el-col :sm="12" style="margin: 0 auto;">
			<el-form :model="timestampData"  label-width="70px">
				<el-row :gutter="5">
					<el-col :sm="10">
						<el-form-item label="实时">
							<el-input readonly  v-model.number="timestampData.currentTimestamp">
								<template v-slot:append>时间戳</template>
							</el-input>
						</el-form-item> 
					</el-col>
					<el-col :sm="2">
						<el-button  @click="copy(JSON.stringify(timestampData.currentTimestamp))" type="primary" plain>复制</el-button>
					</el-col>
					<el-col :sm="10">
						<el-form-item label="实时">
							<el-input readonly v-model.number="timestampData.currentDate">
								<template v-slot:append>北京时间</template>
							</el-input>
						</el-form-item> 
					</el-col>
					<el-col :sm="2">
						<el-button  @click="copy(timestampData.currentDate)" type="primary" plain>复制</el-button>
					</el-col>
				</el-row>
				
				<el-row :gutter="5" style="margin-top:5px;" >
					<el-col :sm="11">
						<el-form-item label="">
							<el-input v-model.number="timestampData.inputTimestamp" @input="inputClick(1)">
								<template v-slot:prepend>时间戳</template>
								<template v-slot:append>秒/毫</template>
							</el-input>
						</el-form-item> 
					</el-col>
				
					<el-col :sm="11">
						<el-form-item label="转换为">
							<el-input readonly v-model.number="timestampData.outiputDate">
								<template v-slot:append>北京时间</template>
							</el-input>
						</el-form-item> 
					</el-col>
					<el-col :sm="2">
						<el-button  @click="copy(JSON.stringify(timestampData.outiputDate))" type="primary" plain>复制</el-button>
					</el-col>
				</el-row>
				
				<el-row :gutter="5">
					<el-col :sm="11">
						<el-form-item>
							<el-input v-model="timestampData.inputDate" @input="inputClick(2)">
								<template v-slot:prepend>北京时间</template>
							</el-input>
						</el-form-item> 
					</el-col>
					<el-col :sm="11">
						<el-form-item label="转换为">
							<el-input readonly v-model.number="timestampData.outinputTimestamp">
								<template v-slot:append>时间戳</template>
							</el-input>
						</el-form-item> 
					</el-col>
					<el-col :sm="2">
						<el-button  @click="copy(JSON.stringify(timestampData.outinputTimestamp))" type="primary" plain>复制</el-button>
					</el-col>
				</el-row>
				
				<el-row>
				  <section class="content element-doc content">
					  <h3 id="ji-ben-yong-fa">北京时间</h3>
					  <p>北京时间是中国采用国际时区东八时区的区时作为标准时间。北京时间并不是北京（东经116.4°）的地方时间，而是东经120°的地方时间，故东经120度地方时比北京的地方时早约14分半钟。因为北京处于国际时区划分中的东八区，同格林尼治时间（世界时）整整相差8小时（即北京时间=世界时+8小时），故命名为“北京时间”。东八区包括的范围从东经112.5°到东经127.5°，以东经120°为中心线，东西各延伸7.5°，总宽度为15°的区域。</p>
				  </section>
				</el-row>
			</el-form>
		</el-col>
		</div>
</template>

<script setup>
import {ref} from 'vue'
import {moduleEvent as moduleaction} from '../../common/actionrecord'
import {clipboard as copy} from '../../common/actionrecord'	
	let timestampData = ref({
		moduleid: 2007,
		currentTimestamp:"",
		currentDate:"",
		inputTimestamp:"",
		outiputDate:"",
		inputDate:"",
		outinputTimestamp:"",
	})
	
//页面预览事件	
moduleaction(timestampData.value.moduleid,1);	

//实时计算时间
setInterval(function actualTime () {
	getActualTime(new Date);
},1000);

let getActualTime = (date) => {
		let yy=date.getFullYear();
		let mm =date.getMonth()+1;
		let dd =date.getDate();
		let hh =date.getHours();
		let mf =date.getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
		let ss =date.getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
		let currentTime = yy+'-'+mm+'-'+dd+' '+hh+':'+mf+':'+ss;
		timestampData.value.currentTimestamp=new Date().getTime();
		timestampData.value.currentDate=currentTime;
	return currentTime;
}
getActualTime(new Date);

//触发
let inputClick = (click) => {
	if(click==1){
		var inputTimestamp=timestampData.value.inputTimestamp;
		if(!inputTimestamp){
			timestampData.value.outiputDate="";
			return;
		}
		let date=new Date(inputTimestamp);
		timestampData.value.outiputDate=getActualTime(date);
	}
	if(click==2){
		var inputDate=timestampData.value.inputDate;
		if(!inputDate){
			timestampData.value.outinputTimestamp="";
			return;
		}
		let time=new Date(inputDate).getTime();
		timestampData.value.outinputTimestamp=time;
	}
}

let init = (date) => {
	timestampData.value.inputDate=getActualTime(date);
	timestampData.value.outinputTimestamp=date.getTime();
	timestampData.value.inputTimestamp=date.getTime();
	timestampData.value.outiputDate=getActualTime(date);
}
init(new Date);
</script>
<style>

</style>